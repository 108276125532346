<template>
  <div class="w-full">
    <div
      ref="slider"
      class="relative z-10 w-full"
    >
      <UiSliderHorizontal
        ref="productsSlider"
        class="swiper-products--wide"
        classes="swiper-products--wide !-mx-2.5 !px-2.5 !-my-2.5 !py-2.5"
        :autoplay="false"
        :options="props.options"
      >
        <MagentoCategoryProductSliderBox
          v-for="(product, index) in props.products"
          :key="`desktop-wide-${product?.sku ?? index}`"
          :product="product"
        />
      </UiSliderHorizontal>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWishlist } from '@customer/composables/useWishlist/useWishlist'
import { useMagentoCategoryProductsSlider } from '../composables/useMagentoCategoryProductsSlider'
import type { MagentoCategoryProductsSliderProps } from '../MagentoCategoryProductsSlider.types'

const props = withDefaults(defineProps<MagentoCategoryProductsSliderProps>(), {
  autoplay: false,
  options: () => ({
    slidesPerGroup: 4,
  }),
})

const { isLoggedIn } = useCustomer()
const { getWishlist } = useWishlist()
const { slider, productsSlider } = useMagentoCategoryProductsSlider()

defineExpose({
  productsSlider,
})

const loginWatcher = watch(
  isLoggedIn,
  async () => {
    if (isLoggedIn.value) {
      await getWishlist()
      loginWatcher()
    }
  },
  { immediate: true },
)
</script>
